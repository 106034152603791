.facebookIcon {
  padding: 0 !important;
  border-radius: 3px !important;
  margin: 0;
  vertical-align: baseline;
  white-space: nowrap;
  font-size: 16px !important;
  height: 40px;
  position: relative;
  font-weight: 400 !important;
  background-image: url('https://dev.id3.expertus.com.ua/media/images/fbIcon.png');
  background-color: transparent!important;
  border: 0!important;
  width: 40px;
  background-size: 40px;
  background-repeat: no-repeat;
  cursor: pointer;
  }
  .blockHide{
    display: none;
  }
  .googleIcon {
    padding: 0 !important; 
    border-radius: 3px !important;
    margin: 0;
    vertical-align: baseline;
    white-space: nowrap;
    font-size: 16px !important;
    height: 40px;
    position: relative;
    font-weight: 400 !important;
    background-image: url('https://dev.id3.expertus.com.ua/media/images/googleIcon.png');
    width: 40px;
    background-size: 40px;
    background-repeat: no-repeat;
    cursor: pointer;
    }

  .nsm7Bb-HzV7m-LgbsSe.Bz112c-LgbsSe .nsm7Bb-HzV7m-LgbsSe-Bz112c{
    padding: inherit!important;
    height: 25px!important;
    width: 25px!important;
    margin: 0 auto!important;
  }
  .errRegistr{
    text-align: center;
    margin-top: 100px;
    height: 70%;
    line-height: 29px;
    
  }
  .googleIcon div{
        background: none;
  }
  .googleIcon svg{
        opacity: 0.1;
  }
  .googleIcon iframe{
        opacity: 0.1;
  }